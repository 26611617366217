import { httpMixin } from "@/mixins/httpMixin";
import TheLoader from "@/components/TheLoader";

export const dynamicContentMixin = {
  mixins: [httpMixin],

  data: () => {
    return {
      componentsLoading: 0,
      components: []
    };
  },

  components: {
    TheLoader,
    boldgridSection: () => ({
      component: import("@/components/BoldgridSection")
    }),
    longText: () => ({
      component: import("@/components/LongText")
    }),
    explainer: () => ({
      component: import("@/components/Explainer")
    }),
    paragraphImagedLeft: () => ({
      component: import("@/components/ParagraphImagedLeft")
    }),
    paragraphImagedRight: () => ({
      component: import("@/components/ParagraphImagedRight")
    }),
    paragraphTripleImage: () => ({
      component: import("@/components/ParagraphTripleImage")
    }),
    paragraphIcon: () => ({
      component: import("@/components/ParagraphIcon")
    }),
    centredParagraph: () => ({
      component: import("@/components/CentredParagraph")
    })
  },

  computed: {
    loading: function() {
      return this.componentsLoading > 0;
    }
  },

  methods: {
    fetchContent: function(pageName) {
      if (this.components.length) return Promise.resolve();
      this.componentsLoading++;
      return this.fetchFromAPI("content/" + pageName).then(components => {
        components.forEach(component => {
          component.is = component.type[0].target_id.replace(/_/g, "-");
          this.components.push(component);
        });
        this.componentsLoading--;
        return components;
      });
    }
  }
};
