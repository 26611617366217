<template>
  <main>
    <TheLoader v-if="loading" />
    <PageTitle v-if="pageTitle !== null">{{ pageTitle }}</PageTitle>
    <component
      v-for="component in components"
      :key="component.uuid[0].value"
      :is="component.is"
      :data="component"
    ></component>
  </main>
</template>

<script>
import { dynamicContentMixin } from "@/mixins/dynamicContentMixin.js";
import { visualMixin } from "@/mixins/visualMixin.js";
import PageTitle from "@/components/PageTitle.vue";

export default {
  mixins: [dynamicContentMixin, visualMixin],

  components: {
    PageTitle
  },

  props: {
    contentEndpoint: String,
    pageTitle: {
      type: String,
      default: null
    }
  },

  created: function() {
    this.fetchContent(this.contentEndpoint).catch(this.handleError);
  }
};
</script>
